import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'consumableAssets',
  components: {
    appStrings,
    DatePicker,
    commonHelper
  },
  props: ['rowScanData','getAuditHistory','getAuditHeader'],
  watch: {
    currentPage: function() {
      this.getAssetConsumableDetails()
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAssetConsumableDetails()
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      payload: null,
      matchFlag: false,
      showValueSetModal:false,
      consumableAssetsData: [
        {
          item_code_vset: null,
          quantity: null
        }
      ],
      consumableAssetsFields: [
        { key: 'add',
          stickyColumn: true,
        },
        { key: 'remove', stickyColumn: true },
        {
          key: 'item_code_vset',
          label:'Item Code'
        },
        {
          key: 'quantity',
        }
        // {
        //   key: 'scan_closed',
        //   class: 'textDecos'
        // },
        // {
        //   key:'scan_assets',
        //   class: 'textDecos'
        // },
        // {
        //   key: 'add_consumable',
        //   class: 'textDecos'
        // },
      ],
      savedConsumableAssetsData: [],
      savedConsumableAssetsFields: [
        // {
        //   key: 'asset_num',
        //   label:'Asset Number'
        // },
        {
          key: 'asset_type'
        },
        {
          key:'item_code'
        },
        {
          key:'qty',
          label:'Quantity'
        },
        { key: 'remove', 
          stickyColumn: true 
        },
        // {
        //   key: 'asset_item'
        // },
        // {
        //   key:'description',
        //   label:'Item Description'
        // },
        // {
        //   key:'view_details'
        // }
        
      ],
      selectAllCheckBox: false,
      selectedRow: [],
      editMode: false,
      mismatchedActive: false,
      matchedActive: false,
      notFoundActive: false,
      checkFlag: false,
      specIndex:null
    };
  },
  validations: {},
  mounted() {
    this.getAssetConsumableDetails()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          //   this.showHideAddFaLocationModal(true);
        this.postConsumables()
        }
        // else if (actionName === 'edit' || actionName === 'update') {
        //   this.editMode = true;
        //   this.eventBus.$emit('auditFlag', this.editMode);
        // }
      }
    });
  },
  methods: {
    getData() {},
    addNewRow() {
    this.consumableAssetsData.push({
          item_code_vset: null,
          quantity: null
        })
    },
    makeToast(content) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: 'danger',
        solid: true
      });
    },
    removeRow(index) {
    this.consumableAssetsData.splice(index, 1);
    },
    openValueSetModal(vsetCode,index) {
        this.parent_value_set_id = null;
        this.vsetCode = vsetCode;
        this.specIndex=index
        this.showValueSetModal = true;
        if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
          this.parent_value_set_id = 'CONSUMABLE';
        }
      
        this.setTimeVsetCode = setTimeout(() => {
          const vSetData = {
            valueSetName: vsetCode,
            multiFlag: null
          };
          this.eventBus.$emit('valueSetCode', vSetData);
        }, 0);
      },
      selectedvalueSet(item) {
        if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
        //   this.itemType = {
        //     value: item.value_code,
        //     text: item.value_meaning
        //   };
        this.consumableAssetsData[this.specIndex].item_code_vset = item.value_code
        } 
      },
      closeValueSetModal() {
        this.showValueSetModal = false;
      },
      clearVsetValues(vsetCode,index) {
            this.consumableAssetsData[index].item_code_vset = null
      },
      postConsumables() {
        const payload = {
          audit_hdr_id: this.rowScanData.audit_hdr_id,
          audit_dtl: {
            audit_dtl_id: this.rowScanData.audit_dtl_id,
            audit_line: [
              // {
              // audit_dtl_id: 0,
              // audit_hdr_id: 0,
              // quantity: null,
              // item_code_vset: null,
              // bar_code: null,
              // }
            ]
          }
        };
        this.consumableAssetsData.map(ele => {
          if (ele.item_code_vset !== null && ele.quantity!==null) {
            payload.audit_dtl.audit_line.push({
              item_code_vset: ele.item_code_vset,
              quantity:ele.quantity,
              audit_line_id:0,
            });
          }
        });
        this.loader = true;
        this.$store
          .dispatch('assets/postAddEditConsumables', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.showAlert = true;
              (this.isSuccess = true), 
              (this.responseMsg = resp.data.message);
              // this.editMode = false;
              this.totalRows = resp.data.data.total_elements;
              this.getAuditHistory()
              this.getAssetConsumableDetails()
              this.getAuditHeader()
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      getAssetConsumableDetails() {
        const payload = {
          auditDtlId: this.rowScanData.audit_dtl_id,
          auditHdrId:this.rowScanData.audit_hdr_id,
          queryParams:{
            _page: this.currentPage - 1,
          _limit: this.perPage,
          }
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getConsumableAssetDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.savedConsumableAssetsData = resp.data.data.page;
              // this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
      removeData(data){
        const payload = {
          dtlId:data.item.audit_line_id
        };
        this.loader = true;
        this.$store
          .dispatch('assets/deleteItems', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.showAlert = true;
              this.isSuccess = true; 
              this.responseMsg = resp.data.message;
              this.getAssetConsumableDetails()
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
