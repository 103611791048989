import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { format } from 'date-fns';


export default {
  name: 'defaultData',
  components: {
    appStrings,
    DatePicker,
    commonHelper,
  },
  props: ['populateData'],
  data() {
    return {
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      payload: null,
      startDate:format(new Date(), 'dd-MMM-yyyy'),
      endDate:format(this.getfuturesDay(), 'dd-MMM-yyyy'),
      assignedTo:null,
      selectAllCheckBox: false,
      selectedRow:[],
      showHideDefaultDataModalFlag:false,
    };
  },
  validations: {
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          if(new Date(this.startDate)<=new Date(this.endDate)){
            this.populateData(this.startDate,this.endDate,this.assignedTo)
          }else{
            this.$bvToast.toast("Start Date can not be greater than End Date", {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
            this.endDate=null;
          }
          //   this.showHideAddFaLocationModal(true);
        }
      }
    });
  },
  methods: {
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    getData() {
    },
    getfuturesDay(date = new Date()) {
      const previous = new Date(date.getTime());
      previous.setDate(date.getDate() + 6);
    
      return previous;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
